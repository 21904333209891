const baseSpacing = 30
const scale = 7.5
const baseFontSize = 17 // 18
const fontScale = baseSpacing / scale // 4

const fontSizing = (factor, lineHeight = 0.75) => ({
  size: `${baseFontSize + factor * fontScale}px`,
  height: `${baseSpacing + (factor + lineHeight) * fontScale}px`,
  // maxWidth chosen to be ~50 characters wide
  // see: https://ux.stackexchange.com/a/34125
  maxWidth: `${baseSpacing * (baseFontSize + (factor + 1) * fontScale)}px`,
})

export default {
  global: {
    baseSpacing,
    colors: {
      focus: "rgba(0,0,0,0.15)",
      "dark-1": "#333333",
      brand: "#000",
      text: {
        light: "#000",
        dark: "#fff",
      },
    },
    hover: {
      background: "black",
      color: "white",
    },
    font: {
      family: "akzidenz-grotesk,sans-serif",
      ...fontSizing(0),
    },
    focus: {
      border: {
        color: "focus",
        weight: 0,
      },
    },
    borderSize: {
      xsmall: "1px",
      small: "2px",
      medium: `${baseSpacing / 6}px`, // 4
      large: `${baseSpacing / 2}px`, // 12
      xlarge: `${baseSpacing}px`, // 24
    },
    breakpoints: {
      small: {
        value: baseSpacing * 32, // 768
        borderSize: {
          xsmall: "1px",
          small: "2px",
          medium: `${baseSpacing / 6}px`, // 4
          large: `${baseSpacing / 4}px`, // 6
          xlarge: `${baseSpacing / 2}px`, // 12
        },
        edgeSize: {
          none: "0px",
          hair: "1px", // for Chart
          xxsmall: "2px",
          xsmall: `${baseSpacing / 8}px`, // 3
          small: `${baseSpacing / 8}px`, // 6
          medium: `${baseSpacing / 2}px`, // 12
          large: `${baseSpacing}px`, // 24
          xlarge: `${baseSpacing * 2}px`, // 48
        },
        size: {
          xxsmall: `${baseSpacing}px`, // 24
          xsmall: `${baseSpacing * 2}px`, // 48
          small: `${baseSpacing * 4}px`, // 96
          medium: `${baseSpacing * 8}px`, // 192
          large: `${baseSpacing * 16}px`, // 384
          xlarge: `${baseSpacing * 32}px`, // 768
          full: "100%",
        },
      },
      medium: {
        value: baseSpacing * 64, // 1536
      },
      large: {}, // anything above 'medium'
    },
    edgeSize: {
      none: "0px",
      hair: "1px", // for Chart
      xxsmall: `${baseSpacing / 8}px`, // 3
      xsmall: `${baseSpacing / 4}px`, // 6
      small: `${baseSpacing / 3}px`, // 12
      medium: `${baseSpacing}px`, // 24
      large: `${baseSpacing}px`, // 48
      xlarge: `${baseSpacing * 2}px`, // 96
      responsiveBreakpoint: "small",
    },
    input: {
      padding: `${baseSpacing / 3}px`,
      weight: "normal",
    },
    control: {
      border: {
        radius: 0,
      },
    },
  },
  text: {
    xsmall: { ...fontSizing(-1.5) },
    small: { ...fontSizing(-1) },
    medium: { ...fontSizing(0) },
    large: { ...fontSizing(0) },
    xlarge: { ...fontSizing(1) },
    xxlarge: { ...fontSizing(1) },
  },
  paragraph: {
    xsmall: { ...fontSizing(-1.5) },
    small: { ...fontSizing(-1) },
    medium: { ...fontSizing(0, -0.75) },
    large: { ...fontSizing(0, 20) },
    xlarge: { ...fontSizing(1) },
    xxlarge: { ...fontSizing(1) },
  },
  button: {
    extend: {
      textTransform: "uppercase",
      fontWeight: "500",
    },
    color: {
      dark: "white",
      light: "black",
    },
    border: {
      radius: 0,
      color: "black",
    },
    padding: {
      vertical: `${baseSpacing / 2.5}px`,
      horizontal: `${baseSpacing}px`,
    },
    primary: {
      color: {
        dark: "light",
        light: "black",
      },
    },
  },
  anchor: {
    fontWeight: "100",
    textDecoration: "underline",
  },
  heading: {
    extend: `
      text-rendering: optimizeLegibility;
      text-transform: uppercase;
    `,
    font: {
      family: "akzidenz-grotesk,sans-serif",
      transform: "uppercase",
    },
    level: {
      1: {
        font: {
          weight: 500,
        },
        small: { ...fontSizing(1) },
        medium: { ...fontSizing(8.25, -0.75) },
        large: { ...fontSizing(8.25) },
        xlarge: { ...fontSizing(8.25) },
      },
      2: {
        font: {
          weight: 500,
        },
      },
      3: {
        font: {
          weight: 500,
        },
      },
      4: {
        font: {
          weight: 500,
        },
      },
    },
  },
}
