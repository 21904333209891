import { graphql, useStaticQuery } from "gatsby"
import { Location } from "@reach/router"
import React from "react"

import Image from "gatsby-image"
import StickyHeader from "./StickyHeader"

const Header = props => {
  const { index, thanks } = useStaticQuery(
    graphql`
      query {
        index: file(relativePath: { eq: "backdrop_header_image.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 10000, quality: 90) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
        thanks: file(relativePath: { eq: "backdrop_thanks_header.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 10000, quality: 90) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    `
  )
  return (
    <header>
      <Location>
        {({ navigate, location }) => (
          <>
            {location.pathname === "/" ? (
              <>
                <StickyHeader />
                <Image
                  objectFit="cover"
                  objectPosition="top center"
                  fluid={index.childImageSharp.fluid}
                />
              </>
            ) : (
              <>
                <StickyHeader logoColor="#ffffff" />
                <a href="https://backdrophome.com">
                  <Image
                    objectFit="cover"
                    objectPosition="top center"
                    fluid={thanks.childImageSharp.fluid}
                  />
                </a>
              </>
            )}
          </>
        )}
      </Location>
    </header>
  )
}

export default Header
