import React, { useState } from "react"
import styled from "styled-components"
import useInterval from "../lib/useInterval"
import useWindowScrollPosition from "@rehooks/window-scroll-position"

import Logo from "./Logo"

const isServer = typeof window === "undefined"
const useWindowScroll = isServer ? () => ({}) : useWindowScrollPosition

const Container = styled.div`
  height: 50px;
  left: 0;
  position: fixed;
  right: 0;
  transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
  top: 0;
  z-index: 800;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;

  &.hidden {
    top: -100px;
    @media (min-width: 768px) {
      top: -200px;
    }
  }

  svg.header-logo {
    height: auto;
    position: absolute;
    right: 9px;
    top: 8px;
    width: 61px;

    @media (min-width: 768px) {
      right: 25px;
      top: 18px;
      width: 128px;
    }
  }
`

const StickyHeader = ({ logoColor = "#000000" }) => {
  const [lastScrollPos, setLastScrollPos] = useState(0)
  const [isHidden, setIsHidden] = useState(false)
  const options = {
    throttle: 200,
  }
  const position = useWindowScroll(options)
  const scrollY = position.y

  useInterval(() => {
    const body = document.body
    const html = document.documentElement

    const windowHeight = isServer ? 0 : window.innerHeight
    const documentHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )
    if (scrollY === lastScrollPos) {
      return
    }
    if (scrollY > lastScrollPos && scrollY > 0) {
      setIsHidden(true)
    } else {
      if (scrollY + windowHeight < documentHeight) {
        setIsHidden(false)
      }
    }
    setLastScrollPos(scrollY)
  }, 250)

  return (
    <Container className={isHidden ? "hidden" : ""}>
      <a href="https://backdrophome.com">
        <Logo fill={logoColor} />
      </a>
    </Container>
  )
}

export default StickyHeader
