import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import { Grommet } from "grommet"

import "./layout.css"
import backdropTheme from "./theme"
import Header from "./Header"
import Footer from "./Footer"
import ConstrainedContainer from "./ConstrainedContainer"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Grommet theme={backdropTheme}>
        <ConstrainedContainer>
          <Helmet>
            <link rel="stylesheet" href="https://use.typekit.net/niq1pzc.css" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
        </ConstrainedContainer>
        <Footer />
      </Grommet>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
